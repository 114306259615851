import { Checklists } from "types/Checklist";

export const useSortChecklists = (checklists: Checklists) => {
  const levelsStore = useLevels();
  const { allLevels } = storeToRefs(levelsStore);

  const lessonsStore = useLessons();
  const { allLessons } = storeToRefs(lessonsStore);

  const levelById = (levelId: string) => {
    return (
      allLevels.value.find((level) => level.id === levelId)?.displayOrder ?? 0
    );
  };

  const lessonById = (lessonId: string) => {
    return (
      allLessons.value.find((lesson) => lesson.id === lessonId)?.displayOrder ??
      0
    );
  };

  var sortedChecklists = [...checklists];

  sortedChecklists.sort((a, b) => {
    // If Level is missing, treat as -Infinity
    const levelA = a.levelId !== undefined ? levelById(a.levelId) : 0;
    const levelB = b.levelId !== undefined ? levelById(b.levelId) : 0;

    // Compare Level first
    if (levelA < levelB) return -1;
    if (levelA > levelB) return 1;

    // If Lesson is missing, treat as -Infinity
    const lessonA = a.lessonId !== undefined ? lessonById(a.lessonId) : 0;
    const lessonB = b.lessonId !== undefined ? lessonById(b.lessonId) : 0;

    // Then compare Lesson
    if (lessonA < lessonB) return -1;
    if (lessonA > lessonB) return 1;

    return 0;
  });

  return sortedChecklists;
};
